import { FunctionComponent } from 'react';

import logo from 'theme/images/logo.png';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutLogo: FunctionComponent<Props> = ({ altText, width, height }) => ((
    <StyledComponent className="layout-logo">
        <img
            className="logo-img"
            src={logo.src}
            alt={altText || 'Gruzilla'}
            width={width}
            height={height}
        />
    </StyledComponent>
));

export default LayoutLogo;