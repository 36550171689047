import { FunctionComponent } from 'react';

import { Routes } from 'types/routes';

import LayoutContainer from 'components/layout/LayoutContainer';
import Link from 'components/layout/Link';
import Logo from 'components/layout/Logo';

import StyledComponent from './styles';
import { Props } from './types';

const WrapperPublicBlocksFooter: FunctionComponent<Props> = () => ((
    <StyledComponent className="wrapper-public-blocks-footer">
        <LayoutContainer>
            <div className="columns">
                <div className="column-logo">
                    <Logo />
                </div>
                <nav className="column-nav">
                    <Link
                        className="nav-link"
                        href={Routes.PublicHomePricingPlans}
                    >
                        Pakiety
                    </Link>
                    <Link
                        className="nav-link"
                        href={Routes.PublicHomeContact}
                    >
                        Kontakt
                    </Link>
                    <Link
                        className="nav-link"
                        href={Routes.PublicPrivacyPolicy}
                    >
                        Polityka prywatności
                    </Link>
                    <Link
                        className="nav-link"
                        href={Routes.PublicRegulation}
                    >
                        Regulamin
                    </Link>
                </nav>
            </div>
            <div className="bottom">
                <div className="bottom-copyright">Copyright © 2022. All Rights Reserved.</div>
            </div>
        </LayoutContainer>
    </StyledComponent>
));

export default WrapperPublicBlocksFooter;
