import { FunctionComponent } from 'react';
import classNames from 'classnames';

import { Routes } from 'types/routes';

import LayoutContainer from 'components/layout/LayoutContainer';
import Link from 'components/layout/Link';
import Logo from 'components/layout/Logo';

import StyledComponent from './styles';
import { Props } from './types';

const WrapperPublicBlocksTopbar: FunctionComponent<Props> = ({ className }) => {
    return (
        <StyledComponent
            className={classNames('wrapper-public-blocks-topbar-desktop', className)}
        >
            <LayoutContainer>
                <div className="columns">
                    <div className="column-brand">
                        <Link
                            className="logo-link"
                            href={Routes.PublicHome}
                        >
                            <Logo
                                width={240}
                                height={66}
                            />
                        </Link>
                    </div>
                    <nav className="column-nav">
                        <Link
                            className="nav-link"
                            href="/"
                        >
							Home
                        </Link>
                        <Link
                            className="nav-link"
                            href={Routes.PublicHomePricingPlans}
                        >
							Pakiety
                        </Link>
                        <Link
                            className="nav-link"
                            href={Routes.PublicHomeContact}
                        >
							Kontakt
                        </Link>
                    </nav>
                </div>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default WrapperPublicBlocksTopbar;
