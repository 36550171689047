import styled from '@emotion/styled';

import { lightenDarkenColor } from 'utils/colors';

import vars from 'theme/styles/vars';

export default styled.div`
	width: 100%;
	position: relative;
	padding: 4em 0 2em 0;
	background-color: ${vars.colorPrimaryDark};

	.columns {
		display: flex;
		flex-flow: column wrap;
		justify-content: space-between;
		align-items: center;
		gap: 2em;

		.column-logo {
			position: relative;
			width: 10em;
		}

		.column-nav {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 2em;

			.nav-link {
				margin: 0.3em 0;
				color: ${vars.colorTextWhite};

				&:hover {
					color: ${lightenDarkenColor(vars.colorTextDark, 100)};
				}

				&:last-of-type {
					margin-right: 0;
				}
			}
		}
	}

	.bottom {
		border-top: 0.02em solid ${vars.colorPrimaryGray};
		padding: 2em 0 0 0;
		margin: 4em 0 0 0;
		color: ${vars.colorTextLight};
		display: flex;
		justify-content: flex-start;
	}

	@media all and (max-width: ${vars.mobileL}) {
		.columns {
			display: flex;
			flex-flow: column;
			gap: 3em;
			font-size: 1.3em;
			margin: 0 2em;
			align-items: flex-start;

			.column-nav {
				flex-flow: column;
				align-items: flex-start;
			}
		}
		.bottom {
			margin: 3em 2em;
			flex-flow: column wrap;
			gap: 2em;
		}
	}
`;
