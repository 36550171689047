import styled from '@emotion/styled';

export default styled.div`
    .link-tag {
        text-decoration: none;
        color: inherit;

        &:hover {
            color: inherit;
        }
    }
`;