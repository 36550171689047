import { FunctionComponent } from 'react';
import Link from 'next/link';
import classnames from 'classnames';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutLink: FunctionComponent<Props> = ({ children, className, ...props }) => ((
    <StyledComponent className={classnames('layout-link', className)}>
        <Link {...props}>
            <a className="link-tag">
                {children}
            </a>
        </Link>
    </StyledComponent>
));

export default LayoutLink;