import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
	padding: 1em 0;
	background-color: ${vars.colorSecondaryDark};

	.columns {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 1em;

		.column-brand {
			max-width: 12em;

			.layout-logo {
				.logo-img {
					width: 100%;
					height: auto;
				}
			}
		}

		.column-button {
			width: 4em;
			height: 4em;
			border: 0;
			background: 0;
			display: flex;
			flex-flow: column wrap;
			justify-content: center;
			gap: 0.6em;

			.line {
				display: block;
				width: 100%;
				height: 4px;
				background-color: ${vars.colorPrimaryLight};
				border-radius: ${vars.defaultRadius};
				transition: opacity 0.4s ease-in-out, transform 0.8s ease,
					margin 0.5s linear;
			}

			&.is-active {
				.line {
					&:nth-child(1) {
						transform: rotate(137deg) translate(13px, -5px);
					}

					&:nth-child(2) {
						opacity: 0;
					}

					&:nth-child(3) {
						transform: rotate(-137deg) translate(11px, 5px);
					}
				}
			}
		}
	}

	.active-nav {
		.column-nav {
			padding: 2em 0;
			display: flex;
			flex-flow: column;
			gap: 1.4em;

			.nav-link {
				font-size: 1.2em;
				color: ${vars.colorTextWhite};
			}
		}
	}

	&.hidden-nav {
        display: none;
    }
`;
