const translations = {
    consentModal: {
        title: '',
        description: 'Korzystając z naszego Serwisu akceptujesz, że w Twoim urządzeniu końcowym zostaną zainstalowane pliki cookies, które umożliwiają nam świadczenie usług. Brak zgody na pliki cookies oznacza, że pewne funkcjonalności strony mogą być niedostępne. Pamiętaj, że zawsze możesz zmienić te ustawienia. Pełne informacje na temat przetwarzania Twoich danych osobowych oraz plików cookies znajdziesz tutaj: <a href=\'/polityka-prywatnosci.pdf\'>Polityka Prywatności.</a>',
        primaryBtn: {
            text: 'Zapoznałem się z informacją (nie wyświetlaj więcej tego komunikatu).',
        },
        secondaryBtn: {
            text: 'Ustawienia',
        },
    },
    settingsModal: {
        title: 'Ustawienia zaawansowane',
        saveSettingsBtn: 'Zapisz ustawienia',
        acceptAllBtn: 'Akceptuję',
        closeBtnLabel: 'Zamknij',
        necessary: {
            title: 'Niezbędne',
            description: 'Korzystanie z plików cookies oznaczonych jako niezbędne konieczne jest dla prawidłowego funkcjonowania Serwisu. Niezbędne pliki cookies są instalowane w szczególności na potrzeby zapamiętywania sesji logowania lub wypełniania formularzy, a także w celach związanych z ustawieniem opcji prywatności.',
        },
        analytical: {
            title: 'Funkcjonalne i Analityczne',
            description: 'Funkcjonalne pliki cookies zapamiętują i dostosowują Serwis do wyborów dokonanych przez Użytkownika. Brak zgody może powodować, że Serwis nie będzie działał prawidłowo.</br>Korzystanie z plików cookies oznaczonych jako analityczne ma na celu sprawdzenie liczby wizyt i źródeł ruchu w Serwisie. Pliki te pozwalają badać statystyki i poprawiać wydajność Serwisu. Jeśli Użytkownik nie zezwoli na stosowanie tych plików cookies, Administrator nie będzie wiedzieć, że Użytkownik odwiedził Serwis.',
        },
    },
};

export default translations;
