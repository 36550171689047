export default {
    fontPrimary: 'Lato, sans-serif',

    colorPrimaryGreen: '#3FBC36',
    colorPrimaryDark: '#303030',
    colorPrimaryWhite: '#EFEFEF',
    colorPrimaryGray: '#676767',
    colorPrimaryBlue: '#0069C1',
    colorPrimaryLight: '#f5f5fa',
    colorSecondaryDark: '#0C0C0C',
    colorSecondaryGray: '#D2D2D2',
    colorSecondaryBlue: '#959DD2',

    colorSuccess: '#4CAF50',
    colorError: '#da626a',
    colorDisabled: '#D8D8D8',

    colorTextDark: '#303030',
    colorTextLight: '#999999',
    colorTextWhite: '#FFFFFF',
    colorTextBlue: '#608AED',
    colorTextBlueLighter: '#A9C3FF',

    defaultRadius: '0.3em',
    defaultShadow: 'rgba(149, 157, 165, 0.080) 0px 4px 10px',

    desktopXL: '1920px',
    desktopL: '1750px',
    desktopM: '1600px',
    desktopS: '1360px',
    desktopXS: '1200px',

    tabletL: '1024px',
    tabletS: '768px',

    mobileL: '640px',
    mobileM: '480px',
    mobileS: '360px',
    mobileXS: '281px',
};
