import { FunctionComponent, useEffect } from 'react';

import { Props } from './index';

declare let initCookieConsent: any;

const LayoutCookiesPanel: FunctionComponent<Props> = () => {
    const translations = require('locales/pl/cookiePanel')?.default;

    useEffect(() => {
        if(typeof initCookieConsent === 'undefined' || !initCookieConsent) return;
        const cookieconsent = initCookieConsent();

        cookieconsent.run({
            current_lang : 'pl',

            onAccept : () => {
                if(cookieconsent.allowedCategory('analytics')) {
                    //TODO: Set gtm consents
                    // grantGtmConsent();
                }
            },

            gui_options: {
                consent_modal : {
                    layout : 'cloud',               // box/cloud/bar
                    position : 'bottom center',     // bottom/top + left/right/center
                    transition: 'slide',             // zoom/slide
                },
                settings_modal : {
                    layout : 'box',                 // box/bar
                    // position : 'left',           // left/right
                    transition: 'slide',             // zoom/slide
                },
            },

            languages : {
                en : {
                    consent_modal : {
                        title: translations.consentModal.title,
                        description: translations.consentModal.description,
                        primary_btn: {
                            text: translations.consentModal.primaryBtn.text,
                            role: 'accept_all',  //'accept_selected' or 'accept_all'
                        },
                        secondary_btn: {
                            text : translations.consentModal.secondaryBtn.text,
                            role : 'settings',   //'settings' or 'accept_necessary'
                        },
                    },
                    settings_modal : {
                        title: translations.settingsModal.title,
                        save_settings_btn: translations.settingsModal.saveSettingsBtn,
                        accept_all_btn: translations.settingsModal.acceptAllBtn,
                        close_btn_label: translations.settingsModal.closeBtnLabel,
                        blocks : [
                            {
                                title: translations.settingsModal.necessary.title,
                                description: translations.settingsModal.necessary.description,
                                toggle : {
                                    value : 'necessary',
                                    enabled : true,
                                    readonly: true,
                                },
                            }, {
                                title: translations.settingsModal.analytical.title,
                                description: translations.settingsModal.analytical.description,
                                toggle : {
                                    value : 'analytics',
                                    enabled : false,
                                    readonly: false,
                                },
                            },
                        ],
                    },
                },
            },
        });
    }, []);

    return (null);
};

export default LayoutCookiesPanel;